
































import {
  Component,
  Vue,
  Watch
} from "vue-property-decorator"
import {
  api
} from '@/api'
import {
  WechatModule
} from "@/store/modules/wechat"
import {
  onScroll
} from "@/utils/func"
import {
  ChatModule
} from "@/store/modules/chat"

import GlobalFooter from '@/components/global/footer.vue'
import Search from '@/components/global/search.vue'

@Component({
  name: 'addrss-book',
  components: {
    GlobalFooter,
    Search
  }
})
export default class AddressBook extends Vue {
  private hasNextPage = true
  private pageNo = 1
  private pageSize = 15
  private list: any = []
  private defChatAvatar: string = 'https://oss.guojiangmedia.com/wxbackend/default-avatar.png'
  private isRequest = false

  private get deviceId(): number {
    return WechatModule.deviceId
  }

  private get newFriendNum(): number {
    return ChatModule.newFriendNum
  }

  private mounted() {
    this.getList()
    this.isRequest = false
  }

  /**
   * @func 监听滚动
   */
  private listenScroll(e: any) {
    onScroll({
      e: e,
      scrollUp: () => {
        console.log('')
        if (this.list.length > 0) {
          this.getList()
        }
      }
    })
  }

  /**
   * @func 获取好友列表
   */
  private async getList() {
    if (this.isRequest) return;
    this.isRequest = true;
    if (!this.hasNextPage) return
    try {
      const param: any = {
        robot_id: this.deviceId,
        offset: this.pageNo,
        num: this.pageSize
      }
      const res: any = await api.getFriendsList(param);
      !res.friends || res.friends.length < this.pageSize ?
        this.hasNextPage = false :
        this.pageNo++
      if (res.friends && res.friends.length) {
        res.friends.map((val: any) => {
          this.list.push(val)
        })
      }
      this.isRequest = false
    } catch (err) {
      this.isRequest = false
      console.log(err)
    }
  }

  /**
   * @func 点击好友
   */
  private chooseFri(item: any) {
    this.$router.push({
      name: 'FriendDesc',
      query: {
        username: item.friend_detail.wxid
      }
    })
  }

  /**
   * @func 搜索
   */
  private toSearch() {
    this.$router.push({
      name: 'Search'
    })
  }

  /**
   * @func 跳转到群聊
   */
  private toChatroom() {
    this.$router.push({
      name: 'Chatroom'
    })
  }

  /**
   * @func 跳转到新好友列表
   */
  private toNewFriend() {
    ChatModule.changeNewFriendNum(0)
    this.$router.push({
      name: 'NewFriend'
    })
  }
}
